<template>
  <WebDocumentViewer :report="$route.params.report" :params="params" />
</template>

<script>
import WebDocumentViewer from "../components/report/WebDocumentViewer.vue";
export default {
  name: "report-page",
  components: { WebDocumentViewer },
  data() {
    return {
      params: {},
    };
  },
  computed: {
    pObject() {
      return {
        name: this.$route?.query?.objectName ?? "objectId",
        id: this.$route?.params?.objectId ?? 0,
      };
    },
  },
  created() {
    if (this.pObject.id > 0) {
      this.params[this.pObject.name] = this.pObject.id;
    }
  },
};
</script>

<style></style>
