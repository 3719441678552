<template>
  <v-card height="calc(100vh - 110px)" style="position: relative" class="ml-1">
    <div
      ref="viewer"
      style="position: absolute; left: 0; right: 0; top: 0; bottom: 0"
      data-bind="dxReportViewer: $data"
    ></div>
  </v-card>
</template>

<script>
import ko from "knockout";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import "devexpress-reporting/dx-webdocumentviewer";
import ruDxReporting from "../../modules/devextreme/localization/messages/dx-reporting.ru.json";

export default {
  name: "view-WebDocumentViewer",
  props: {
    report: [String, Object],
    params: Object,
    localize: String,
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    ko.cleanNode(this.$refs.viewer);
  },
  methods: {
    updateToken() {
      ajaxSetup.ajaxSettings = {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      };
    },
    init() {
      const urlParams = this.params
        ? Object.entries(this.params)
            .map(([key, val]) => `${key}=${val}`)
            .join("&")
        : "";

      // Обновление токена
      this.updateToken();
      this.$watch(() => {
        return this.$store.state.AccessToken;
      }, this.updateToken);

      const rep =
        typeof this.report === "object" ? this.report.id : this.report;

      const viewerOptions = {
        reportUrl: ko.observable(`${rep}?${urlParams}`), // The URL of a report.
        invokeAction: "/DXXRDV",
        requestOptions: {
          host: process.env.VUE_APP_SERVER_URL.substr(
            0,
            process.env.VUE_APP_SERVER_URL.length - 4
          ),
          // Use this line for the ASP.NET MVC backend
          // invokeAction: "/WebDocumentViewer/Invoke"
          // Use this line for the ASP.NET Core backend
          invokeAction: "DXXRDV",
        },
        callbacks: {
          // Handle the CustomizeLocalization event to load the DevExpress Localization Service JSON files.
          CustomizeLocalization: function (s, e) {
            e.LoadMessages(ruDxReporting);
            /* s.UpdateLocalization({
              Reset: "Сбросить",
              Submit: "Применить",
            }); */
          },
          CustomizeParameterEditors: function (s, e) {
            const tag = e.parameter.tag && JSON.parse(e.parameter.tag);
            const showTime = tag && tag.ShowTime;
            if (e.parameter.type === "System.DateTime" && !showTime) {
              e.info.editor = { ...e.info.editor };
              e.info.editor.extendedOptions = {
                ...e.info.editor.extendedOptions,
                type: "date",
              };
            }
          },
        },
      };

      ko.applyBindings(viewerOptions, this.$refs.viewer);
    },
  },
};
</script>

<style lang="sass"></style>
